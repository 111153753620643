<template>
  <div class="page-layout__content col-md-8">
    <CCol col="12" md="8" sm="6">
      <CCard v-for="(mod,key) in allows" v-bind:key="key">
        <CButton
            @click="open(key)"
            tag="button"
            color="light"
            block
            class="text-left shadow-none card-header"
        >
          <h5 class="m-0">{{ getTranslation('level_module_' + key) }}</h5>
        </CButton>
        <CCollapse :show="openIds.includes(key)">
          <CCardBody>

            <div v-for="permission in mod" v-bind:key="permission.id" class="form-check checkbox">
              <input class="form-check-input" v-model="permissionIds" :id="permission.id" type="checkbox"
                     :value="permission.id">
              <label class="form-check-label" :for="permission.id">{{ permission.title }}</label>
            </div>

          </CCardBody>
        </CCollapse>
      </CCard>
      <CCardFooter>
        <CButton type="submit" size="sm" color="primary" @click="updateLevel">
          <CIcon name="cil-check-circle"/>
          {{ getTranslation('save') }}
        </CButton>
        <CButton type="reset" size="sm" color="danger" class="ml-2"
                 @click="()=>{$router.push({name:'auth.levels.getList'})}">
          <CIcon name="cil-ban"/>
          {{ getTranslation('cancel') }}
        </CButton>
      </CCardFooter>
    </CCol>
  </div>
</template>

<script>
export default {
  name: "Edit",
  data() {
    return {
      allows: [],
      permissionIds: [],
      item: {
        id: null,
        name: ''
      },
      permissions: [],
      openIds: [],

    }

  },
  created() {
    this.fetchLevel(this.$route.params.id).then(() => {
      this.setHeadings({
        title: this.item.name,
        toBack: {
          label: this.getTranslation('toBack', this.getTranslation('levels')),
          route: { name: 'auth.levels.getList' }
        },
        breadcrumbs: [
          {
            text: this.getTranslation('levels'),
            to: { name: 'auth.levels.getList' }
          },
          {
            text: this.item.name
          }
        ],
      })
    })
  },
  methods: {
    updateLevel() {
      this.axios.put(this.$urls.level.level.url.replace(':id', this.$route.params.id), { permissions: this.permissionIds })
          .then(() => {
            this.snotifySuccess('saveSuccessMsg');
            this.loadUser(true).then(() => {
              this.$router.push({ name: 'auth.levels.getList' })
            });
          })
    },

    open(id) {
      if ( this.openIds.includes(id) )
        this.openIds.splice(this.openIds.indexOf(id), 1);
      else
        this.openIds.push(id);
    },

    fetchLevel(id) {
      return this.axios.get(this.$urls.level.level.url.replace(':id', id))
          .then(response => {

            this.allows = response.data.data.allows;
            this.item = response.data.data.item;
            this.permissions = response.data.data.item.permissions;


            this.permissionIds = response.data.data.item.permissions.map(item => {
              return item.id;
            })

          })
    }

  }
}
</script>

<style scoped>

</style>